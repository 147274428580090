import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Dialog } from "primereact/dialog";
import MakoListagem from "../MakoListagem";
import { DateFiltroTemplate, TextoFiltroTemplate } from "../MakoFiltrosCabecalho";

const Modal = ({}, ref) => {
    const [visible, setVisible] = useState(false);
    const [usuario, setUsuario] = useState(false);

    const abrirModal = ({ usuario }) => {
        setVisible(true);
        setUsuario(usuario);
    };

    const fecharModal = () => {
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const colunas = [
        {
            field: "data_hora",
            header: "Data/Hora",
            style: { minWidth: "80px" },
            dateFormat: "yyyy-MM-dd HH:mm:ss",
            filter: true,
            filterElement: DateFiltroTemplate,
        },
        {
            field: "detalhes",
            header: "Detalhes",
            style: { minWidth: "400px" },
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        { field: "ip", header: "IP", style: { minWidth: "80px" }, filter: true, filterElement: TextoFiltroTemplate },
        {
            field: "usuario.username",
            header: "Usuário",
            style: { minWidth: "100px" },
            filter: true,
            filterElement: TextoFiltroTemplate,
            filterField: "usuario__username",
        },
    ];

    return (
        <Dialog
            header={`Histórico de Logs (ID: ${usuario.id}, Nome de usuário: ${usuario.username})`}
            visible={visible}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "76vw" }}
            onHide={fecharModal}
            maximizable
        >
            <MakoListagem
                urlPesquisa={"/logs/historico-logs/"}
                colunas={colunas}
                msgTabelaVazia="Nenhum log registrado"
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    data_hora: { value: null, matchMode: "equals" },
                    ip: { value: null, matchMode: "equals" },
                    detalhes: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    usuario__username: { value: "", matchMode: "equals" },
                }}
            />
        </Dialog>
    );
};

export const MakoHistoricoLogs = forwardRef(Modal);
