import React, { useCallback, useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";

import { dataToStr } from "@/assets/util/datas";
import useToast from "@/hooks/useToast";
import useEmpresa from "@/hooks/useEmpresa";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useHttp from "@/hooks/useHttp";

export const MakoDropdownCompetenciaEstoque = ({ habilitarInativas, buscarPorEmpresa = true, ...props }) => {
    const [competenciasEstoque, setCompetenciasEstoque] = useState([]);
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showError } = useToast();
    const { empresaSelecionadaId } = useEmpresa();
    const { httpGet } = useHttp();

    const desabilitarCompetenciaEstoque = (competencia) => {
        if (habilitarInativas) return false;
        return !competencia.vigente;
    };

    const buscarCompetenciasEstoque = useCallback(async () => {
        const url =
            empresaSelecionadaId && buscarPorEmpresa
                ? `/produtos/competencias-movimentacoes-estoques/?ativa=true&empresas=${empresaSelecionadaId}`
                : `/produtos/competencias-movimentacoes-estoques/?ativa=true`;

        const handlers = {
            200: ({ data }) => {
                setCompetenciasEstoque(data.results);
            },
        };

        showLoading();
        await httpGet({ url }, handlers);
        hideLoading();
    }, [showLoading, hideLoading, showError, empresaSelecionadaId, buscarPorEmpresa, httpGet]);

    useEffect(() => {
        buscarCompetenciasEstoque();
    }, [buscarCompetenciasEstoque]);

    const competenciaEstoqueSelecionadaTemplate = (option, props) => {
        if (option) {
            return (
                <span>{`${option.nome} ${dataToStr(option.data_inicio, "dd/MM/yyyy")} até ${dataToStr(
                    option.data_fim,
                    "dd/MM/yyyy"
                )}`}</span>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const opcaoCompetenciaEstoqueTemplate = (option) => {
        return (
            <span>{`${option.nome} ${dataToStr(option.data_inicio, "dd/MM/yyyy")} até ${dataToStr(
                option.data_fim,
                "dd/MM/yyyy"
            )}`}</span>
        );
    };

    return (
        <Dropdown
            options={competenciasEstoque}
            optionValue="id"
            optionLabel="codigo"
            valueTemplate={competenciaEstoqueSelecionadaTemplate}
            itemTemplate={opcaoCompetenciaEstoqueTemplate}
            optionDisabled={desabilitarCompetenciaEstoque}
            emptyMessage="Nenhum registro disponível."
            emptyFilterMessage="Nenhum registro encontrado."
            placeholder={loading ? "Buscando..." : "Selecione"}
            disabled={loading}
            {...props}
        />
    );
};
