import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoDropdownCompetenciaFinanceira } from "@/components/MakoInputs/MakoDropdownCompetenciaFinanceira";
import { MakoDropdownHistoricoPadrao, MakoInputCheckBox } from "@/components/MakoInputs";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoInputPerfil } from "@/components/MakoInputs/MakoInputPerfil";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { FormikAutoSave } from "@/components/FormikAutoSave";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import { FINANCEIRO_RECEBIMENTO_TEMPLATERATEIO_PADRAO } from "@/assets/constants/parametros";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { parseData } from "@/assets/util/datas";

import useParam from "@/hooks/useParam";

export const Lancamento = ({
    submit,
    recebimento,
    parcelar,
    setParcelar = () => {},
    setRecebimentos = () => {},
    setSubmitParcelas = () => {},
    handleSubmitRecebimento = () => {},
}) => {
    const [competencias, setCompetencias] = useState([]);

    const { getParam } = useParam();

    const { setFieldValue, setValues, ...formik } = useFormik({
        initialValues: {
            id: 0,
            empresa: null,
            devedor: null,
            conta_financeira: null,
            venda: null,
            forma_recebimento: null,
            documento: "",
            historico_padrao: null,
            historico_original: "",
            valor_total: 0,
            data_emissao: null,
            data_lancamento: new Date(),
            competencia_financeira: null,
            template_rateio: null,
            origem_fracionamento: null,
            plano_orcamentario: null,
            vencimento: null,
            caixa: null,
        },
        onSubmit: handleSubmit,
    });

    function handleSubmit({ vencimento, ...values }, formikHelpers) {
        try {
            const formSchema = Yup.object().shape({
                empresa: Yup.number().required("O campo 'empresa' é obrigatório.").typeError("Selecione uma empresa."),
                devedor: Yup.object().required("O campo 'sacado' é obrigatório.").typeError("Selecione um sacado."),
                conta_financeira: Yup.number().nullable().notRequired().default(null),
                venda: Yup.number().nullable().notRequired().default(null),
                forma_recebimento: Yup.number().nullable().notRequired().default(null),
                caixa: Yup.number().nullable().notRequired().default(null),
                documento: Yup.string()
                    .required("O campo 'documento' é obrigatório.")
                    .max(30, "O comprimento máximo para esse campo é 30 caracteres."),
                historico_padrao: Yup.object()
                    .required("O campo 'histórico' é obrigatório.")
                    .typeError("Selecione um histórico."),
                valor_total: Yup.number()
                    .required("O campo 'valor bruto' é obrigatório.")
                    .min(0.01, "O campo 'valor bruto' não pode ser ZERO."),
                data_lancamento: Yup.date()
                    .required("O campo 'data de lançamento' é obrigatório.")
                    .typeError("Informe uma data válida."),
                data_emissao: Yup.date()
                    .required("O campo 'data de emissão' é obrigatório.")
                    .max(Yup.ref("data_lancamento"), "A data de emissão não pode superior a data de lançamento.")
                    .typeError("Informe uma data válida."),
                competencia_financeira: Yup.number().nullable().notRequired().default(null),
                template_rateio: Yup.number().required("O campo 'template de rateio' é obrigatório."),
                plano_orcamentario: Yup.number().nullable().notRequired().default(null),
            });
            const dadosValidos = formSchema.validateSync(values, { abortEarly: false });
            if (parcelar) {
                handleSubmitRecebimento(dadosValidos);
            } else {
                const formSchema = Yup.object().shape({
                    vencimento: Yup.date()
                        .required("O campo 'vencimento' é obrigatório.")
                        .typeError("Informe um 'vencimento' válido."),
                });
                formSchema.validateSync({ vencimento }, { abortEarly: false });
                handleSubmitRecebimento({ ...dadosValidos, vencimento }, true);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formikHelpers.setErrors(errorMessages);
            }
        } finally {
            formikHelpers.setSubmitting(false);
        }
    }

    const definirTemplateRateioPadrao = useCallback(
        (value) => {
            setFieldValue("empresa", value.id);
            const param = getParam(FINANCEIRO_RECEBIMENTO_TEMPLATERATEIO_PADRAO, value.id);
            if (param) setFieldValue("template_rateio", param.valor);
        },
        [setFieldValue, getParam]
    );

    const aposBuscarCompetencia = useCallback((data) => {
        setCompetencias(data);
        return data;
    }, []);

    const periodoEmissao = useMemo(() => {
        let periodo = { minDate: null, maxDate: new Date() };
        if (typeof formik.values.competencia !== "number") return periodo;
        const competencia = competencias.find(({ id }) => id === formik.values.competencia);
        if (competencia) {
            const { data_inicio, data_fim } = competencia;
            periodo.minDate = parseData(data_inicio);
            periodo.maxDate = parseData(data_fim);
        }
        return periodo;
    }, [competencias, formik.values.competencia]);

    useEffect(() => {
        if (recebimento) setValues(recebimento);
    }, [recebimento, setValues]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-6">
                    <Label htmlFor="empresa" obrigatorio label="Empresa" />
                    <MakoDropdownEmpresas
                        id="empresa"
                        name="empresa"
                        value={formik.values.empresa}
                        disabled={!!formik.values.id}
                        onChange={definirTemplateRateioPadrao}
                        className={classNames({ "p-invalid": formik.errors.empresa })}
                    />
                    {formik.errors.empresa && <small className="p-error">{formik.errors.empresa}</small>}
                </div>
                <div className="p-field p-col-12 p-md-6">
                    <Label htmlFor="sacado" obrigatorio label="Sacado" />
                    <MakoInputPerfil
                        id="sacado"
                        name="devedor"
                        disabled={!!formik.values.id}
                        somenteAtivo
                        query="id,nome,identificacao,tipo_pessoa"
                        value={formik.values.devedor}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.devedor })}
                    />
                    {formik.errors.devedor && <small className="p-error">{formik.errors.devedor}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="conta-financeira" label="Conta financeira" />
                    <Dropdown
                        id="conta-financeira"
                        name="conta_financeira"
                        url={`/financeiro/contas-financeiras/?query={id,descricao}&tipo_conta__in=2,3&perfil=${formik.values.empresa}`}
                        buscar={!!formik.values.empresa}
                        optionValue="id"
                        optionLabel="descricao"
                        value={formik.values.conta_financeira}
                        onChange={formik.handleChange}
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="venda" label="Número da venda" />
                    <InputText id="venda" disabled value={formik.values.venda} />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="forma-recebimento" label="Forma" />
                    <Dropdown
                        id="forma-recebimento"
                        name="forma_recebimento"
                        url="/financeiro/formas-recebimentos/?query={id,descricao}&ativo=true&limit=50"
                        disabled={!!formik.values.id}
                        optionValue="id"
                        optionLabel="descricao"
                        value={formik.values.forma_recebimento}
                        onChange={formik.handleChange}
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="documento" obrigatorio label="Documento" />
                    <InputText
                        id="documento"
                        name="documento"
                        disabled={!!formik.values.id}
                        value={formik.values.documento}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.documento })}
                    />
                    {formik.errors.documento && <small className="p-error">{formik.errors.documento}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-5">
                    <Label htmlFor="historico" obrigatorio label="Histórico" />
                    <MakoDropdownHistoricoPadrao
                        filtros={{ tipo: "R" }}
                        id="historico"
                        name="historico_padrao"
                        disabled={!!formik.values.id}
                        value={formik.values.historico_padrao}
                        handleChangeForm={formik.handleChange}
                        setFieldValueForm={setFieldValue}
                        inputTextProps={{
                            value: formik.values.historico_original,
                        }}
                        className={classNames({ "p-invalid": formik.errors.historico_padrao })}
                    />
                    {formik.errors.historico_padrao && (
                        <small className="p-error">{formik.errors.historico_padrao}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="competencia" label="Competência financeira" />
                    <MakoDropdownCompetenciaFinanceira
                        id="competencia"
                        name="competencia"
                        showClear
                        disabled={!!formik.values.id}
                        aposBuscar={aposBuscarCompetencia}
                        value={formik.values.competencia}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.competencia })}
                    />
                    {formik.errors.competencia && <small className="p-error">{formik.errors.competencia}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="data-emissao" obrigatorio label="Data de emissão" />
                    <MakoCalendar
                        id="data-emissao"
                        name="data_emissao"
                        disabled={!!formik.values.id}
                        valueCalendar={formik.values.data_emissao}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.data_emissao })}
                        minDate={periodoEmissao.minDate}
                        maxDate={periodoEmissao.maxDate}
                    />
                    {formik.errors.data_emissao && <small className="p-error">{formik.errors.data_emissao}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="data-lancamento" obrigatorio label="Data de lançamento" />
                    <MakoCalendar
                        id="data-lancamento"
                        name="data_lancamento"
                        valueCalendar={formik.values.data_lancamento}
                        disabled
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.data_lancamento })}
                    />
                    {formik.errors.data_lancamento && (
                        <small className="p-error">{formik.errors.data_lancamento}</small>
                    )}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="valor-bruto" obrigatorio label="Valor" />
                    <MakoInputMoeda
                        id="valor-bruto"
                        name="valor_total"
                        min={0}
                        disabled={!!formik.values.id}
                        valueMoeda={formik.values.valor_total}
                        onChangeMoeda={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.valor_total })}
                    />
                    {formik.errors.valor_total && <small className="p-error">{formik.errors.valor_total}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="template-rateio" obrigatorio label="Template de rateio" />
                    <Dropdown
                        id="template-rateio"
                        name="template_rateio"
                        buscar={!!formik.values.empresa}
                        url={`/financeiro/templates-rateios/?query={id,descricao}&empresa=${formik.values.empresa}`}
                        disabled={!!formik.values.id}
                        optionValue="id"
                        optionLabel="descricao"
                        value={formik.values.template_rateio}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.template_rateio })}
                    />
                    {formik.errors.template_rateio && (
                        <small className="p-error">{formik.errors.template_rateio}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Label htmlFor="origem-fracionamento" label="Origem fracionamento" />
                    <InputText
                        id="origem-fracionamento"
                        name="origem_fracionamento"
                        disabled
                        value={formik.values.origem_fracionamento}
                    />
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="plano-orcamentario" label="Plano orçamentário" />
                    <Dropdown
                        id="plano-orcamentario"
                        name="plano_orcamentario"
                        buscar={!!formik.values.empresa}
                        disabled={!!formik.values.id}
                        url={`/financeiro/planos-orcamentarios/?query={id,descricao}&perfil=${formik.values.empresa}`}
                        optionValue="id"
                        optionLabel="descricao"
                        value={formik.values.plano_orcamentario}
                        onChange={formik.handleChange}
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="caixa" label="Caixa" />
                    <Dropdown
                        id="caixa"
                        name="caixa"
                        disabled={!!formik.values.id}
                        url={`/financeiro/caixas/`}
                        optionValue="id"
                        optionLabel="nome"
                        value={formik.values.caixa}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.caixa })}
                    />
                    {formik.errors.caixa && <small className="p-error">{formik.errors.caixa}</small>}
                </div>
                {!recebimento?.id && (
                    <>
                        {!parcelar && (
                            <div className="p-field p-col-12 p-md-2">
                                <Label htmlFor="vencimento" obrigatorio label="Vencimento" />
                                <MakoCalendar
                                    id="vencimento"
                                    name="vencimento"
                                    minDate={formik.values.data_lancamento}
                                    valueCalendar={formik.values.vencimento}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.vencimento })}
                                />
                                {formik.errors.vencimento && (
                                    <small className="p-error">{formik.errors.vencimento}</small>
                                )}
                            </div>
                        )}
                        <MakoInputCheckBox
                            id="parcelar"
                            name="parcelar"
                            onChange={(e) =>
                                setParcelar(() => {
                                    const newValue = !e.value;
                                    if (newValue) {
                                        setSubmitParcelas(false);
                                        setRecebimentos([]);
                                    }
                                    return newValue;
                                })
                            }
                            checked={parcelar}
                            label="Parcelar?"
                        />
                    </>
                )}
            </div>
            <CamposObrigatorios />
            {(!submit || !parcelar) && (
                <MakoActionsButtons>
                    <Button
                        type="submit"
                        icon={MAKO_ICONS.GRAVAR}
                        label={parcelar ? "Incluir dados básicos" : "Incluir dados"}
                    />
                </MakoActionsButtons>
            )}
            <FormikAutoSave intervalo={500} autosave={submit && recebimento?.id === 0} formik={formik} />
        </form>
    );
};
