import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { ClonarOperacaoModalForm } from "./modals/clonarOperacao";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import permissoes from "@/assets/constants/permissoes";
import { TIPO_ESFERA_FISCAL_CHOICE, TIPO_MOVIMENTACAO_ESTOQUE_CHOICE } from "@/assets/constants/constants";
import { DateFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { PageBase } from "@/components/PageBase";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";
import { BotaoDelete } from "@/components/BotaoDelete";
import { Menu } from "primereact/menu";
import useAuth from "@/hooks/useAuth";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

export const OperacoesFiscaisPage = () => {
    const [opFiscal, setOpFiscal] = useState(null);
    const { httpPatch } = useHttp();
    const { showSuccess } = useToast();
    const { verifyPermission } = useAuth();
    const listagemRef = useRef(null);
    const menu = useRef(null);
    const clonaRef = useRef(null);
    const history = useHistory();

    const alteraSituacao = async () => {
        const handlers = {
            200: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Operação deletada com sucesso!",
                    life: 3000,
                });
                listagemRef.current?.buscarDados();
            },
        };

        await httpPatch(
            { url: `/fiscal/operacoes-fiscais/${opFiscal.id}/`, body: { ativo: !opFiscal.ativo } },
            handlers
        );
    };

    const opcoes = [
        {
            label: "Opções",
            items: [
                {
                    label: "Clonar operação",
                    icon: "pi pi-clone",
                    disabled: !verifyPermission([permissoes.PRODUTO_PRODUTO_PRODUTOS_CONSULTAR]),
                    command: () => clonaRef.current?.abrirModal(opFiscal),
                },
                {
                    label: opFiscal?.ativo ? "Ativar" : "Desativar",
                    icon: opFiscal?.ativo ? "pi pi-chevron-circle-down" : "pi pi-times-circle",
                    disabled: !verifyPermission([permissoes.PRODUTO_PRODUTO_PRODUTOS_EDITAR]),
                    command: () => alteraSituacao(),
                },
            ],
        },
    ];

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_CADASTRO_OPERACAOFISCAL_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2"
                    tooltip="Alterar cadastro de operação fiscal"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/fiscal/cadastros/operacoes-fiscais/form",
                            opFiscal: rowData,
                        })
                    }
                />
                <Button
                    model={opcoes}
                    className="p-button-rounded p-button-info p-mr-2"
                    tooltip="Opções"
                    tooltipOptions={{ position: "left" }}
                    icon={MAKO_ICONS.OPCOES}
                    onClick={(event) => {
                        menu.current.toggle(event);
                        setOpFiscal(rowData);
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_CADASTRO_OPERACAOFISCAL_EXCLUIR]}
                    componente={BotaoDelete}
                    url="/fiscal/operacoes-fiscais/"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={<span>Deseja mesmo excluir a operação fiscal {rowData.id}?</span>}
                    tooltip="Deletar operação fiscal"
                    msgToastErroExclusao="A operação fiscal não pode ser excluída."
                    className="p-mt-1"
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FISCAL_CADASTRO_OPERACAOFISCAL_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/fiscal/cadastros/operacoes-fiscais/form")}
            />
        </>
    );

    const formatTipoMovimento = (valor) => {
        if (valor === "E") return "Entrada";
        return "Saída";
    };

    const formatEsfera = (valor) => {
        if (valor === "D") return "Dentro do estado";
        else if (valor === "E") return "Entre estados";
        return "Internacional";
    };

    const codigoFiltroTemplate = (options) => {
        return <InputNumber value={options.value} onValueChange={(e) => options.filterCallback(e.value)} />;
    };

    const tipoMovimentoFiltroTemplate = (options) => {
        return (
            <Dropdown
                placeholder="Selecione"
                value={options.value}
                options={TIPO_MOVIMENTACAO_ESTOQUE_CHOICE}
                optionValue="id"
                optionLabel="label"
                onChange={(e) => options.filterCallback(e.value)}
            />
        );
    };

    const esferaFiltroTemplate = (options) => {
        return (
            <Dropdown
                placeholder="Selecione"
                value={options.value}
                options={TIPO_ESFERA_FISCAL_CHOICE}
                optionValue="id"
                optionLabel="label"
                onChange={(e) => options.filterCallback(e.value)}
            />
        );
    };

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { minWidth: "50px" },
            filter: true,
            filterElement: codigoFiltroTemplate,
        },
        { field: "descricao", header: "Descrição", style: { minWidth: "300px" }, filter: true },
        {
            field: "tipo_movimento",
            header: "Tipo",
            filter: true,
            filterElement: tipoMovimentoFiltroTemplate,
            action: (e) => formatTipoMovimento(e.tipo_movimento),
            style: { minWidth: "120px" },
        },
        {
            field: "esfera",
            header: "Esfera",
            filter: true,
            filterElement: esferaFiltroTemplate,
            action: (e) => formatEsfera(e.esfera),
            style: { minWidth: "200px" },
        },
        {
            field: "vigencia_inicial",
            header: "Início",
            style: { minWidth: "120px" },
            dateFormat: "dd/MM/yyyy",
            filter: true,
            filterElement: DateFiltroTemplate,
        },
        {
            field: "vigencia_final",
            header: "Fim",
            style: { minWidth: "120px" },
            dateFormat: "dd/MM/yyyy",
            filter: true,
            filterElement: DateFiltroTemplate,
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "150px" },
        },
    ];

    return (
        <PageBase>
            <Menu model={opcoes} popup ref={menu} id="popup_menu" />
            <MakoListagem
                ref={listagemRef}
                titulo="Operações Fiscais"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={`/fiscal/operacoes-fiscais/`}
                filtarPorEmpresa
                naoBuscarSemEmpresa
                filtros={{
                    id: { value: "", matchMode: "equals" },
                    tipo_movimento: { value: "", matchMode: "equals" },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    esfera: { value: "", matchMode: "equals" },
                    aplicacao: { value: "", matchMode: "equals" },
                    vigencia_inicial: { value: "", matchMode: "equals" },
                    vigencia_final: { value: "", matchMode: "equals" },
                }}
                configTabela={{
                    paginator: true,
                    lazy: true,
                    filterDisplay: "menu",
                    scrollable: true,
                }}
            />
            <ClonarOperacaoModalForm
                opFiscal={opFiscal}
                aposClonar={() => listagemRef.current?.buscarDados()}
                ref={clonaRef}
            />
        </PageBase>
    );
};
