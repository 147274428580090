import React from "react";

import { Button } from "primereact/button";

import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { Label } from "@/components/Label";

import { Listagem } from "./listagem";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";

export const BuscarParcela = () => {
    return (
        <>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-10">
                    <Label htmlFor="parcela" label="Adicionar outra parcela" />
                    <MakoAutoComplete
                        id="parcela"
                        name="parcela"
                        urlSearch=""
                        field="descricao"
                        placeholder="Digite o nome do cliente ou número da venda"
                        disabled
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Button
                        icon={MAKO_ICONS.PESQUISAR}
                        type="button"
                        className="p-button-info"
                        label="Buscar"
                        style={{ top: "1.9rem", width: "100%" }}
                    />
                </div>
            </div>
            <div className="p-mt-3">
                <Listagem />
            </div>
        </>
    );
};
