import React, { useState, useEffect } from "react";

import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";

import { MakoButton as Button } from "@/components/MakoButton";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { Label } from "@/components/Label";
import MakoListagem from "@/components/MakoListagem";

import { gerarParcelas } from "@/assets/util/calcFinanceiros";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

export const Form = ({ recebimento = {}, successCallback = () => {}, cancelCallback = () => {} }) => {
    const [qtdFracionamentos, setQtdFracionamentos] = useState(2);
    const [fracionamentos, setFracionamentos] = useState([]);
    const [valorPrimeira, setValorPrimeira] = useState(0);
    const [value, setValue] = useState(null);

    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess, showWarning } = useToast();
    const { httpPost } = useHttp();

    async function handleSubmit() {
        const _fracionamentos = fracionamentos.map((frac) => ({
            recebimento_id: value.id,
            documento: frac.documento,
            valor: frac.valor,
        }));
        const handlers = {
            200: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Fracionamento realizado com sucesso!",
                    life: 3000,
                });
                successCallback();
            },
            400: ({ err }) => {
                showWarning({
                    summary: "Falha no fracionamento",
                    detail: err?.msg,
                    life: 3000,
                });
            },
        };
        showLoading();
        await httpPost(
            { url: "/financeiro/incluir-fracionamentos/", body: { fracionamentos: _fracionamentos } },
            handlers
        );
        hideLoading();
    }

    async function fracionarRecebimento() {
        if (valorPrimeira > 0) {
            let _fracionamentos = [
                {
                    documento: `${value.documento} FRA-1`,
                    vencimento: value.vencimento,
                    valor: valorPrimeira,
                },
            ];
            const fracionamentos = gerarParcelas(value.valor - valorPrimeira, qtdFracionamentos - 1);
            setFracionamentos([
                ..._fracionamentos,
                ...fracionamentos.map((fracionamento, index) => ({
                    documento: `${value.documento} FRA-${index + 2}`,
                    vencimento: value.vencimento,
                    valor: fracionamento,
                })),
            ]);
        } else {
            const fracionamentos = gerarParcelas(value.valor, qtdFracionamentos);
            setFracionamentos(
                fracionamentos.map((fracionamento, index) => ({
                    documento: `${value.documento} FRA-${index + 1}`,
                    vencimento: value.vencimento,
                    valor: fracionamento,
                }))
            );
        }
    }

    useEffect(() => {
        if (recebimento) {
            const { quantidade_parcelas, numero_parcela } = recebimento || {};
            setValue({
                ...recebimento,
                parcela: numero_parcela === 0 ? "ENTRADA" : `${numero_parcela}/${quantidade_parcelas}`,
            });
        }
    }, [recebimento]);

    const colunas = [
        { field: "documento", header: "Documento" },
        { field: "vencimento", header: "Vencimento", dateFormat: "dd/MM/yyyy" },
        { field: "valor", header: "Valor", money: true },
    ];

    return (
        <div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="documento" label="Documento" />
                    <InputText id="documento" name="documento" disabled value={value?.documento || ""} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="parcela" label="Parcela" />
                    <InputText id="parcela" name="parcela" disabled value={value?.parcela || ""} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="valor" label="Valor" />
                    <MakoInputMoeda id="valor" name="valor" disabled valueMoeda={value?.valor} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="qtd-fracionamentos" label="Quant. parcelas" obrigatorio />
                    <InputNumber
                        id="qtd-fracionamentos"
                        min={2}
                        value={qtdFracionamentos}
                        onValueChange={(e) => setQtdFracionamentos(e.value)}
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="valor-primeira" label="Valor da primeira" obrigatorio />
                    <MakoInputMoeda
                        id="valor-primeira"
                        min={0}
                        max={value?.valor - 0.01}
                        valueMoeda={valorPrimeira}
                        tooltip="Deixe 0 para fracionar igualmente."
                        tooltipOptions={{ position: "bottom" }}
                        onChangeMoeda={(e) => setValorPrimeira(e.value)}
                    />
                </div>
            </div>
            <MakoActionsButtons className="p-mb-3">
                <Button
                    type="submit"
                    label="Fracionar"
                    icon={MAKO_ICONS.FRACIONAR}
                    className="p-button-secondary"
                    onClick={fracionarRecebimento}
                />
                <Button
                    type="button"
                    label="Limpar"
                    icon={MAKO_ICONS.LIMPAR_FORM}
                    className="p-button-warning"
                    onClick={() => setFracionamentos([])}
                />
            </MakoActionsButtons>
            <MakoListagem colunas={colunas} dadosLocal={fracionamentos} configTabela={{ paginator: true }} />
            <MakoActionsButtons className="p-mt-3">
                <Button loading={loading} icon={MAKO_ICONS.GRAVAR} label="Gravar" onClick={handleSubmit} />
                <Button
                    label="Cancelar"
                    loading={loading}
                    icon={MAKO_ICONS.CANCEL}
                    className="p-button-danger"
                    onClick={cancelCallback}
                />
            </MakoActionsButtons>
        </div>
    );
};
