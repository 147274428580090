import React from "react";

import { Dados } from "./dados";
import { BuscarParcela } from "./buscar-parcela";

export const Parcelas = () => {
    return (
        <>
            <Dados />
            <BuscarParcela />
        </>
    );
};
