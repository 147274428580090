import React, { forwardRef, memo, useCallback, useEffect, useRef, useState } from "react";
import { formatarCasasDecimais, parseNumberToMoneyHTML } from "@/assets/util/util";
import MakoListagem from "@/components/MakoListagem";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import * as Yup from "yup";
import { Dropdown } from "@/components/Dropdown";
import useTransferencia from "@/hooks/useTransferencia";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import permissoes from "@/assets/constants/permissoes";
import { Divider } from "primereact/divider";
import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";
import { axiosGet, axiosPost, axiosPut } from "@/services/http";
import { BlockUI } from "@/components/BlockUI";

export const DocumentosFiscaisForm = () => {
    const notasRef = useRef(null);
    const itensRef = useRef(null);
    const [itens, setItens] = useState([]);
    const { transferencia, handlePreencherTransferencia } = useTransferencia();
    const { empresaSelecionada, empresaSelecionadaId } = useEmpresa();
    const { showSuccess, showWarning, showError } = useToast();
    const history = useHistory();

    const { setFieldValue, setValues, resetForm, ...formik } = useFormik({
        enableReinitialize: true,
        initialValues: {
            operacao_fiscal: null,
            subtotal: 0,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                operacao_fiscal: Yup.number().required("O campo é obrigatório"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const { cidade } =
                (await empresaSelecionada?.enderecoperfil_set?.length) > 0
                    ? empresaSelecionada?.enderecoperfil_set[0]
                    : null;
            const body = {
                modelo: "55",
                operacao_fiscal: values.operacao_fiscal,
                natureza_operacao: null,
                venda: null,
                transferencia: transferencia.id,
                ce_destino: transferencia.ce_destino,
                evento_id: null,
                app_label: "transferencias",
                model: "transferenciamercadoria",
                tipo_operacao: 1,
                dados_basicos: {
                    codigo_uf: cidade?.estado?.codigo_uf || null,
                    emitente: empresaSelecionadaId,
                    requer_identificacao: true,
                    indicador_final: 0,
                    municipio_ocorrencia: cidade?.id || null,
                    transportador: null,
                    indicador_pagamento: 3,
                    formato_impressao: 0,
                    nota_referencia: [],
                    info_adicionais: "",
                },
                itens: itens
                    .filter((e) => e.operacao_fiscal === values.operacao_fiscal)
                    .map((item) => {
                        return {
                            ...item,
                            unidade: item.unidade_medida.sigla,
                            sku: item.sku.id,
                            valor_total: item.quantidade * item.valor_fiscal,
                            valor_unitario: item.valor_fiscal,
                            valor_desconto: 0,
                            valor_frete: 0,
                            valor_seguro: 0,
                            quantidade_nf: item.quantidade * item.cubagem,
                            outras_despesas: 0,
                            municipio_issqn: cidade?.id || null,
                            municipio_incidencia: cidade?.id || null,
                            app_label: "transferencias",
                            model: "itemtransferenciamercadoria",
                        };
                    }),
            };

            const resposta = await axiosPost("/fiscal/inserir-nota-fiscal/", body);

            if (resposta.status === 200) {
                notasRef.current?.buscarDados();
                showSuccess({
                    summary: "Sucesso!",
                    detail: "Nota fiscal incluida com sucesso!",
                    life: 3000,
                });
            } else if (resposta.status === 400) {
                showWarning({
                    summary: "Falha ao inserir",
                    detail: `Não foi possível inserir a nota fiscal, verifique as informações da operação fiscal e tente novamente (${resposta.data.erro}.)`,
                    life: 5000,
                });
            } else {
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não foi possível inserir a nota fiscal.",
                    life: 3000,
                });
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else {
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não foi possível inserir a nota fiscal.",
                    life: 3000,
                });
            }
        }
    }
    const handleSituacao = useCallback(
        async (situacao) => {
            const { status, data } = await axiosPut(
                `/transferencias/alterar-situacao-transferencia/${transferencia.id}/`,
                {
                    status: situacao,
                }
            );

            if (status === 200) {
                await handlePreencherTransferencia(transferencia.id);
                showSuccess({
                    summary: "Sucesso!",
                    detail: "A situação da transferência foi alterada com sucesso.",
                    life: 10000,
                });
            } else if (status === 409) {
                showWarning({
                    summary: "Falha",
                    detail: data.msg,
                    life: 9000,
                });
            } else
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não foi possível alterar a situação da transferência.",
                    life: 3000,
                });
        },
        [showError, showSuccess, showWarning, handlePreencherTransferencia, transferencia?.id]
    );

    const calcularValorTotalItemTabela = (rowData) => {
        const { quantidade, cubagem, valor_fiscal } = rowData;
        const valorTotal = quantidade * cubagem * valor_fiscal;
        return parseNumberToMoneyHTML(valorTotal);
    };

    const verificaEmissao = (chave) => {
        if (chave) return chave;
        else return "NÃO TRANSMITIDO";
    };

    const verificaProtocolo = (protocolo) => {
        if (protocolo) return protocolo;
        else return "-";
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.ESTOQUE_MOVIMENTACOES_TRANSFERENCIAMERCADORIA_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar"
                    tooltipOptions={{ position: "left" }}
                    disabled={rowData.status === "C" || rowData.status === "T"}
                    onClick={() =>
                        history.push({
                            pathname: "/fiscal/nota-saida/emissao-nota",
                            state: { notaFiscal: rowData },
                        })
                    }
                />
            </div>
        );
    };

    const colunasNota = [
        {
            field: "numero",
            header: "Número",
            filter: true,
            style: { width: "80px" },
        },
        {
            field: "chave_nf",
            header: "Chave",
            action: (e) => verificaEmissao(e.chave_nf),
            filter: true,
            align: "left",
            style: { minWidth: "400px" },
        },
        {
            field: "protocolo",
            header: "Protocolo",
            action: (e) => verificaProtocolo(e.protocolo),
            filter: true,
            style: { minWidth: "150px" },
        },
        {
            field: "natureza_operacao",
            header: "Natureza",
            filter: true,
            style: { width: "80px" },
        },
        {
            field: "valor_total_nf",
            header: "Total Nf",
            filter: true,
            align: "right",
            style: { width: "150px" },
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "120px" },
        },
    ];

    const colunasItem = [
        { field: "sku.codigo", header: "Código", style: { minWidth: "100px" } },
        { field: "sku.descricao_reduzida", header: "Descrição da mercadoria ou produto", style: { minWidth: "300px" } },
        {
            field: "unidade_medida.nome",
            header: "Unidade",
            style: { minWidth: "100px" },
        },
        {
            field: "cubagem",
            header: "Cubagem",
            style: { minWidth: "100px" },
            action: (e) => formatarCasasDecimais(e.cubagem, 5),
        },
        { field: "quantidade", header: "Qtd.", style: { minWidth: "100px" } },
        {
            field: "valor_fiscal",
            header: "Valor unitário (R$)",
            style: { minWidth: "150px" },
            action: (e) => parseNumberToMoneyHTML(e.valor_fiscal),
        },
        {
            field: "valor_total",
            header: "Valor total (R$)",
            style: { minWidth: "150px" },
            action: (e) => calcularValorTotalItemTabela(e),
            frozen: true,
            alignFrozen: "right",
        },
    ];

    const rowClass = (item) => {
        if (item.operacao_fiscal)
            return {
                "mako-table-pending": item.operacao_fiscal === formik.values.operacao_fiscal,
            };
    };

    const listarItens = useCallback(async () => {
        const { status, data } = await axiosGet(
            `/transferencias/itens-transferencia-mercadoria?transferencia=${transferencia.id}`
        );
        if (status === 200) setItens(data.results);
    }, [transferencia?.id]);

    useEffect(() => {
        listarItens();
    }, [listarItens]);

    const rowClassNF = (rowData) => {
        return {
            "mako-table-status-inativo": rowData.status === "C",
            "table-recebimentos-effective": rowData.status === "T",
        };
    };

    const onChageOp = (value) => {
        setFieldValue("operacao_fiscal", value);
        setFieldValue(
            "subtotal",
            itens.reduce((parcial, item) => {
                if (item.operacao_fiscal === value) return parcial + item.valor_fiscal;
                else return parcial;
            }, 0)
        );
    };

    return (
        <div className="p-col-12">
            <BlockUI
                blocked={transferencia?.status?.id === "C" || transferencia?.status?.id === "R"}
                template={<b style={{ fontSize: "2rem" }}>A transferência foi cancelada.</b>}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="inf_complementares">Operação fiscal *</label>
                            <Dropdown
                                id="operacao_fiscal"
                                name="operacao_fiscal"
                                placeholder="Selecione uma operação fiscal"
                                url={`/fiscal/operacoes-fiscais?limit=1000&empresa=${empresaSelecionadaId}`}
                                filter
                                filterBy="id,descricao"
                                optionValue="id"
                                optionLabel="descricao"
                                value={formik.values.operacao_fiscal}
                                onChange={({ value }) => onChageOp(value)}
                                className={classNames({ "p-invalid": formik.errors.operacao_fiscal })}
                            />
                            {formik.errors.operacao_fiscal && (
                                <small className="p-error">{formik.errors.operacao_fiscal}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="subtotal">Subtotal</label>
                            <MakoInputMoeda
                                id="subtotal"
                                name="subtotal"
                                valueMoeda={formik.values.subtotal}
                                onValueChange={formik.handleChange}
                                disabled
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-2 p-mt-5">
                            <MakoControleAcesso
                                permissao={[permissoes.FISCAL_NOTASAIDA_INCLUIR]}
                                componente={Button}
                                icon="pi pi-cog"
                                type="submit"
                                label="Gerar"
                                disabled={
                                    transferencia?.status?.id !== "V" ||
                                    transferencia.tipo_transferencia?.tipo_ce_origem?.id === "C"
                                }
                            />
                            {transferencia?.status?.id === "P" ? (
                                <label style={{ color: "#FF0000" }}>A transferência precisa ser validada</label>
                            ) : null}
                            {transferencia.tipo_transferencia?.tipo_ce_origem?.id === "C" ? (
                                <label style={{ color: "#FF0000" }}>Transferência do tipo consolidado.</label>
                            ) : null}
                        </div>
                        {transferencia?.status?.id === "P" ? (
                            <div className="p-field p-col-12 p-md-1 p-mt-5">
                                <Button
                                    icon="pi pi-check"
                                    type="button"
                                    tooltip="Validar transferência"
                                    severity="secondary"
                                    onClick={() => handleSituacao("V")}
                                />
                            </div>
                        ) : null}
                    </div>
                </form>
            </BlockUI>
            <MakoListagem
                titulo={"Itens da Transferência"}
                ref={itensRef}
                colunas={colunasItem}
                dadosLocal={itens}
                configTabela={{
                    rowClassName: rowClass,
                    scrollable: true,
                    scrollHeight: "400px",
                    frozenWidth: "0.5vw",
                }}
            />
            <br />
            <Divider />
            <MakoListagem
                titulo={"Notas Fiscais"}
                ref={notasRef}
                colunas={colunasNota}
                urlPesquisa={`/fiscal/notas-fiscais?transferencia=${transferencia.id}`}
                configTabela={{
                    paginator: true,
                    lazy: true,
                    scrollable: true,
                    scrollHeight: "400px",
                    frozenWidth: "0.5vw",
                    rowClassName: rowClassNF,
                }}
            />
        </div>
    );
};

export default memo(forwardRef(DocumentosFiscaisForm));
