import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";

import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import useDevolucaoFornecedor from "@/hooks/useDevolucaoFornecedor";
import useHttp from "@/hooks/useHttp";

const BASE_URL = "/compras/parcelas-devolucao-fornecedor-com-entrada/{id}/totais";

const Component = (_, ref) => {
    const [totais, setTotais] = useState({ entrada: 0, abater: 0, receber: 0 });
    const { dadosBasicos } = useDevolucaoFornecedor();
    const { httpGet } = useHttp();

    const fetchData = useCallback(
        async (signal) => {
            const { id } = dadosBasicos || {};
            if (id) {
                const url = BASE_URL.replace("{id}", id);

                const handlers = {
                    200: ({ data }) => {
                        const { entrada, abater, receber } = data;
                        setTotais({ entrada, abater, receber });
                    },
                };

                await httpGet(
                    {
                        url: url,
                        signal,
                    },
                    handlers
                );
            }
        },
        [dadosBasicos]
    );

    useEffect(() => {
        const controller = new AbortController();
        fetchData(controller.signal);
        return () => {
            controller.abort();
        };
    }, [fetchData]);

    useImperativeHandle(ref, () => ({ fetch: fetchData }), [fetchData]);

    return (
        <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-col-12 p-md-2">
                <label htmlFor="total-parcelas">Parcelas da compra</label>
                <MakoInputMoeda id="total-parcelas" name="total_parcelas" disabled value={totais.entrada} />
            </div>
            <div className="p-field p-col-12 p-md-2">
                <label htmlFor="total-abater">Total p/ abater</label>
                <MakoInputMoeda id="total-abater" name="total_abater" value={totais.abater} disabled />
            </div>
            <div className="p-field p-col-12 p-md-2">
                <label htmlFor="total-receber">Total p/ receber</label>
                <MakoInputMoeda id="total-receber" name="total_receber" disabled value={totais.receber} />
            </div>
        </div>
    );
};

export const PainelResumoParcelas = forwardRef(Component);
