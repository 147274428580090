import React, { useCallback, useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { Dropdown as DropdownPR } from "primereact/dropdown";
import useToast from "@/hooks/useToast";
import axios from "axios";
import useHttp from "@/hooks/useHttp";

// IMPORTANTE
// aposBuscar -> sempre utilize useMemo ou useCallback nesta função

const MakoDropdown = (
    { url, buscar = true, options, disabled, setObjects, aposBuscar, placeholder = "Selecione", id = "", ...props },
    ref
) => {
    const [dados, setDados] = useState([]);
    const [loading, setLoading] = useState(false);
    const { showWarning, showError } = useToast();
    const { httpGet } = useHttp();

    const fetchDados = useCallback(
        async (cancelToken) => {
            if (!url || !buscar) return null;

            const handlers = {
                200: async ({ data }) => {
                    let { results } = data;
                    if (typeof aposBuscar === "function") results = await aposBuscar(results);
                    setDados(results);
                    if (typeof setObjects === "function") setObjects(results);
                },
            };

            setLoading(true);
            await httpGet({ url, cancelToken }, handlers);
            setLoading(false);
        },
        [url, buscar, setObjects, aposBuscar, showWarning, showError, httpGet]
    );

    useEffect(() => {
        const { token, ...cancelToken } = axios.CancelToken.source();
        if (options instanceof Array) {
            setDados(options);
        } else {
            fetchDados(token);
        }
        return function clear() {
            cancelToken.cancel();
        };
    }, [fetchDados, options]);

    useImperativeHandle(ref, () => ({ fetch: fetchDados }));

    return (
        <DropdownPR
            options={dados}
            disabled={loading || disabled}
            emptyMessage="Nenhum registro disponível"
            emptyFilterMessage="Nenhum registro encontrado"
            showClear
            inputId={id}
            {...props}
            placeholder={
                !loading ? (
                    placeholder
                ) : (
                    <span>
                        <i
                            className="pi pi-spin pi-spinner"
                            style={{ fontSize: "1rem", float: "right", marginTop: "3px" }}
                        />{" "}
                        Buscando informações...
                    </span>
                )
            }
        />
    );
};

export const DropdownRef = forwardRef(MakoDropdown);
