import React, { useCallback, useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import { Dropdown } from "@/components/Dropdown";
import useToast from "@/hooks/useToast";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useEmpresa from "@/hooks/useEmpresa";
import useDevolucaoFornecedor from "@/hooks/useDevolucaoFornecedor";
import { InputTextarea } from "primereact/inputtextarea";
import useHttp from "@/hooks/useHttp";

export const DocFiscalForm = () => {
    const [informacoes, setInformacoes] = useState("");
    const [exibirItens, setExibirItens] = useState(false);
    const [itensSelecionados, setItensSelecionados] = useState([]);
    const [listaItens, setListaItens] = useState([]);
    const [listaOperacoes, setListaOperacoes] = useState([]);
    const [opcaoFaturamento, setOpcaoFaturamento] = useState(null);
    const modeloNfRef = useRef(null);
    const { dadosBasicos, editavel } = useDevolucaoFornecedor();
    const { empresaSelecionada, empresaSelecionadaId } = useEmpresa();
    const { showSuccess, showWarning, showError } = useToast();
    const { httpGet, httpPost } = useHttp();
    const history = useHistory();
    const listagemRef = useRef();

    async function handleEnviar() {
        try {
            if ((await empresaSelecionada?.enderecoperfil_set?.length) > 0) {
                const { cidade } = await empresaSelecionada?.enderecoperfil_set[0];
                const body = {
                    modelo: modeloNfRef.current,
                    venda: null,
                    operacao_fiscal: itensSelecionados[0].operacao_fiscal,
                    natureza_operacao: null,
                    transferencia: null,
                    model: "devolucaofornecedor",
                    app_label: "compras",
                    evento_id: dadosBasicos.id,
                    tipo_operacao: 1,
                    dados_basicos: {
                        codigo_uf: cidade.estado?.codigo_uf,
                        emitente: empresaSelecionadaId,
                        destinatario: dadosBasicos.fornecedor.id || null,
                        requer_identificacao: dadosBasicos.fornecedor?.id ? true : false,
                        indicador_pagamento: 3,
                        municipio_ocorrencia: cidade.id,
                        transportador: null,
                        indicador_final: 0,
                        formato_impressao: 0,
                        info_adicionais: informacoes,
                        nota_referencia: [],
                    },
                    itens: itensSelecionados.map((item) => {
                        return {
                            ...item,
                            unidade: item.unidade_medida.sigla,
                            sku: item.sku.id,
                            valor_total: item.valor_unitario * item.quantidade_nf,
                            valor_desconto: 0,
                            valor_frete: 0,
                            valor_seguro: 0,
                            quantidade: item.quantidade_nf,
                            outras_despesas: 0,
                            municipio_issqn: cidade.id,
                            municipio_incidencia: cidade.id,
                            evento_id: item.id,
                            app_label: "compras",
                            model: "itemdevolucaofornecedor",
                        };
                    }),
                };

                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso!",
                            detail: "Nota fiscal incluida com sucesso!",
                            life: 3000,
                        });
                        fecharDialog();
                        listagemRef.current?.buscarDados();
                    },
                    400: ({ err }) =>
                        showWarning({
                            summary: "Falha ao inserir",
                            detail: `Não foi possível inserir a nota fiscal, verifique as informações da operação fiscal e tente novamente (${err.erro}.)`,
                            life: 5000,
                        }),
                };

                await httpPost(
                    {
                        url: "/fiscal/inserir-nota-fiscal/",
                        body: body,
                    },
                    handlers
                );
            }
        } catch (error) {
            showWarning({
                summary: "Cadastro Incompleto!",
                detail: "A empresa atual não possui todas as informações registradas, verifique o cadastro e tente novamente.",
                life: 5000,
            });
        }
    }

    const sugerirOperacoesNF = useCallback(async () => {
        const handlers = {
            200: async ({ data }) => {
                let lista = listaItens;
                data.operacoes?.forEach((op, index) => {
                    lista[index]["operacao_fiscal"] = op;
                });
                setItensSelecionados(lista);
                setListaItens(lista);
            },
        };

        await httpPost(
            {
                url: `/fiscal/sugerir-operacoes-fiscais/${empresaSelecionadaId}/`,
                body: {
                    itens: listaItens,
                    devolucao: true,
                },
            },
            handlers
        );
    }, [listaItens, empresaSelecionadaId]);

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_DOCFISCALVENDA_CONSULTAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Editar"
                    tooltipOptions={{ position: "left" }}
                    disabled={rowData.status === "C" || rowData.status === "T" || !editavel}
                    onClick={() =>
                        history.push({
                            pathname: "/fiscal/nota-saida/emissao-nota",
                            state: { notaFiscal: rowData },
                        })
                    }
                />
            </div>
        );
    };

    const verificaEmissao = (chave) => {
        if (chave) return chave;
        else return "NÃO TRANSMITIDO";
    };

    const verificaProtocolo = (protocolo) => {
        if (protocolo) return protocolo;
        else return "-";
    };

    const colunasNota = [
        {
            field: "numero",
            header: "Número",
            filter: true,
            style: { minWidth: "50px" },
        },
        {
            field: "serie",
            header: "Série",
            filter: true,
            style: { minWidth: "50px" },
        },
        {
            field: "modelo",
            header: "Modelo",
            filter: true,
            style: { minWidth: "50px" },
        },
        {
            field: "chave_nf",
            header: "Chave",
            action: (e) => verificaEmissao(e.chave_nf),
            filter: true,
            align: "left",
            style: { minWidth: "400px" },
        },
        {
            field: "protocolo",
            header: "Protocolo",
            action: (e) => verificaProtocolo(e.protocolo),
            filter: true,
            style: { minWidth: "200px" },
        },
        {
            field: "valor_total_nf",
            header: "Total Nf",
            filter: true,
            align: "right",
            style: { minWidth: "120px" },
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "15%" },
        },
    ];

    const editarQuantidade = (options, quantidade, aux) => {
        options.editorCallback(quantidade);
        setListaItens(
            listaItens.map((item) => {
                if (item.aux_quantidade === aux) item.quantidade_nf = quantidade;
                return item;
            })
        );
    };

    const textEditor = (options) => {
        return (
            <InputText
                type="text"
                value={options.value}
                onChange={(e) => editarQuantidade(options, e.target.value, options.rowData.aux_quantidade)}
            />
        );
    };

    const editarOperacao = (options, operacao, aux) => {
        options.editorCallback(operacao);
        setListaItens(
            listaItens.map((item) => {
                if (item.id === aux) item.operacao_fiscal = operacao;
                return item;
            })
        );
    };

    const operacaoEditor = (options) => {
        return (
            <Dropdown
                id="operacao_fiscal"
                name="operacao_fiscal"
                options={listaOperacoes}
                optionValue="id"
                optionLabel="descricao"
                style={{ width: "100%" }}
                value={options.value}
                onChange={(e) => editarOperacao(options, e.target.value, options.rowData.id)}
            />
        );
    };

    const colunasItens = [
        { selectionMode: "multiple", headerStyle: { width: "3em" } },
        { field: "sku.codigo", header: "Código", style: { minWidth: "80px" } },
        { field: "sku.descricao_reduzida", header: "Item", style: { minWidth: "250px" } },
        { field: "sku.item.ncm.codigo", header: "NCM" },
        {
            field: "quantidade",
            header: "Quant",
            style: { minWidth: "100px" },
            decimal: true,
        },
        {
            field: "quantidade_nf",
            header: "Qtd. NF",
            decimal: true,
            style: { minWidth: "100px" },
            editor: (options) => textEditor(options),
        },
        {
            field: "operacao_fiscal",
            header: "Operação Fiscal",
            decimal: true,
            style: { minWidth: "300px" },
            editor: (options) => operacaoEditor(options),
            action: ({ operacao_fiscal }) => (
                <Dropdown
                    id="operacao_fiscal"
                    name="operacao_fiscal"
                    options={listaOperacoes}
                    optionValue="id"
                    optionLabel="descricao"
                    style={{ width: "100%" }}
                    value={operacao_fiscal}
                />
            ),
        },
    ];

    const aposPesquisar = useCallback(async (results) => {
        const _list = results.map((item, index) => ({
            ...item,
            aux_quantidade: index + 1,
            quantidade_nf: item.quantidade,
        }));
        setListaItens(_list);
        return _list;
    }, []);

    const carregaOpcaoFaturamento = useCallback(async () => {
        const handlers = {
            200: ({ data }) => {
                if (data.results?.lenght > 0) setOpcaoFaturamento(data.results[0]);
            },
        };

        await httpGet(
            {
                url: `/fiscal/opcoes-faturamento?limit=1&empresa=${empresaSelecionadaId}`,
            },
            handlers
        );
    }, [empresaSelecionadaId]);

    useEffect(() => {
        carregaOpcaoFaturamento();
    }, [carregaOpcaoFaturamento]);

    const listarOperacoes = useCallback(async () => {
        if (!empresaSelecionadaId) return;

        const params = {
            empresa: empresaSelecionadaId,
            tipo_movimento: "S",
            limit: 1000,
            ativo: true,
        };

        const handlers = {
            200: async ({ data }) => {
                const { enderecoperfil_set = [] } = empresaSelecionada;
                const enderecoEmpresa = enderecoperfil_set?.find(({ principal }) => !!principal);
                const enderecoFornecedor = dadosBasicos?.fornecedor?.enderecoperfil_set?.find(
                    ({ principal }) => !!principal
                );

                if (enderecoEmpresa?.lenght > 0 && enderecoFornecedor?.lenght > 0) {
                    setListaOperacoes(
                        data.results
                            .filter(({ esfera }) =>
                                esfera === "D"
                                    ? enderecoEmpresa[0].cidade.estado.id === enderecoFornecedor[0].cidade.estado.id
                                    : enderecoEmpresa[0].cidade.estado.id !== enderecoFornecedor[0].cidade.estado.id
                            )
                            .map(({ operacao }) => operacao)
                    );
                } else {
                    setListaOperacoes(data.results);
                }
            },
        };

        await httpGet(
            {
                url: "/fiscal/operacoes-fiscais/",
                params,
            },
            handlers
        );
    }, [empresaSelecionadaId, empresaSelecionada, dadosBasicos?.fornecedor?.enderecoperfil_set]);

    useEffect(() => {
        listarOperacoes();
    }, [listarOperacoes]);

    const rowClassNF = (rowData) => {
        return {
            "mako-table-status-inativo": rowData.status === "C",
            "table-recebimentos-effective": rowData.status === "T",
        };
    };

    const fecharDialog = () => {
        setInformacoes(null);
        setExibirItens(false);
    };

    return (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_VENDA_DOCFISCALVENDA_INCLUIR]}
                componente={Button}
                type="button"
                label="Gerar Documento Fiscal"
                onClick={() => {
                    setExibirItens(true);
                    modeloNfRef.current =
                        opcaoFaturamento?.modelo_documento === "QQ"
                            ? dadosBasicos?.cliente?.tipo_pessoa === "PF"
                                ? "65"
                                : "55"
                            : opcaoFaturamento?.modelo_documento === "NFC"
                            ? "65"
                            : "55";
                }}
                disabled={!editavel}
                className="p-mr-2"
            />
            <MakoListagem
                ref={listagemRef}
                titulo="Documentos fiscais da devolução"
                urlPesquisa={`/fiscal/notas-fiscais?evento_id=${dadosBasicos.id}&evento_content_type__model=devolucaofornecedor`}
                colunas={colunasNota}
                configTabela={{
                    lazy: true,
                    paginator: true,
                    rowClassName: rowClassNF,
                }}
            />
            <Dialog
                header={"Selecione itens para emissão do documento fiscal:"}
                visible={exibirItens}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "70vw", display: "block" }}
                onHide={() => fecharDialog(false)}
            >
                <MakoListagem
                    colunas={colunasItens}
                    url={`/compras/item-devolucao-fornecedor?devolucao_fornecedor=${dadosBasicos?.id}`}
                    aposPesquisar={aposPesquisar}
                    configTabela={{
                        editMode: "cell",
                        selectionMode: "multiple",
                        selection: itensSelecionados,
                        onSelectionChange: (e) => setItensSelecionados(e.value),
                    }}
                />
                <div className="p-fluid p-formgrid p-grid p-mt-3">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="informacoes">Informações adicionais</label>
                        <InputTextarea
                            id="informacoes"
                            name="informacoes"
                            rows={3}
                            value={informacoes}
                            onChange={(e) => setInformacoes(e.target.value)}
                        />
                    </div>
                </div>
                <Button
                    type="button"
                    icon="pi pi-plus"
                    label="Gerar nota"
                    className="p-mt-4"
                    disabled={!itensSelecionados}
                    onClick={() => handleEnviar()}
                />
                <Button
                    type="button"
                    icon="pi pi-question"
                    label="Sugerir operações"
                    className="p-mt-4 p-ml-2 p-button-help"
                    onClick={() => sugerirOperacoesNF()}
                />
            </Dialog>
        </>
    );
};
