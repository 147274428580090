import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { MakoCardFinanceiro } from "@/components/MakoCards";

import useLoadingLocal from "@/hooks/useLoadingLocal";
import useEmpresa from "@/hooks/useEmpresa";
import useHttp from "@/hooks/useHttp";

const MemoCardFinanceiro = memo(MakoCardFinanceiro);

const BASE_INDICADORES = {
    a_receber: 0,
    receber_hoje: 0,
    recebido_hoje: 0,
    total_recebido: 0,
};

export const Indicadores = ({ filtros }) => {
    const [indicadores, setIndicadores] = useState(BASE_INDICADORES);

    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { empresaSelecionadaId } = useEmpresa();
    const { httpGet } = useHttp();

    const getIndicadores = useCallback(
        async (signal) => {
            if (!empresaSelecionadaId || !filtros) return;
            const params = {
                empresa: empresaSelecionadaId,
                ...filtros,
            };
            const handlers = {
                200: ({ data }) => setIndicadores(data.result),
            };
            showLoading(true);
            await httpGet({ url: "/financeiro/indicadores/recebimentos/", params, signal }, handlers);
            hideLoading(false);
        },
        [empresaSelecionadaId, filtros, showLoading, httpGet, hideLoading]
    );

    const indicadorAReceber = useMemo(() => {
        return <MemoCardFinanceiro blocked={loading} title="A receber" value={indicadores.a_receber} />;
    }, [indicadores.a_receber, loading]);

    const indicadorAReceberHoje = useMemo(() => {
        return <MemoCardFinanceiro blocked={loading} title="Para hoje" value={indicadores.receber_hoje} />;
    }, [indicadores.receber_hoje, loading]);

    const indicadorRecebidoHoje = useMemo(() => {
        return <MemoCardFinanceiro blocked={loading} title="Recebido hoje" value={indicadores.recebido_hoje} />;
    }, [indicadores.recebido_hoje, loading]);

    const indicadorTotalRecebido = useMemo(() => {
        return <MemoCardFinanceiro blocked={loading} title="Total recebido" value={indicadores.total_recebido} />;
    }, [indicadores.total_recebido, loading]);

    useEffect(() => {
        const controller = new AbortController();
        getIndicadores(controller.signal);
        return () => {
            controller.abort();
        };
    }, [getIndicadores]);

    return (
        <div className="p-fluid p-grid">
            <div className="p-col-12 p-md-3">{indicadorAReceber}</div>
            <div className="p-col-12 p-md-3">{indicadorAReceberHoje}</div>
            <div className="p-col-12 p-md-3">{indicadorRecebidoHoje}</div>
            <div className="p-col-12 p-md-3">{indicadorTotalRecebido}</div>
        </div>
    );
};
