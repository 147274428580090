import { useCallback } from "react";
import useHttp from "@/hooks/useHttp";

const useGetNumeroParcela = (devolucaoId) => {
    const { httpGet } = useHttp();

    const buscarNumeroParcela = useCallback(async () => {
        let _num = 1;
        const handlers = {
            200: ({ data }) => {
                _num = data.numero;
            },
        };

        await httpGet(
            {
                url: `compras/parcelas-devolucao-fornecedor-com-entrada/${devolucaoId}/numero-parcela/`,
            },
            handlers
        );

        return _num;
    }, [devolucaoId]);

    return buscarNumeroParcela;
};

export default useGetNumeroParcela;
