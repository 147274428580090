import React, { useMemo } from "react";

import MakoListagem from "@/components/MakoListagem";

import { formatDocumento } from "@/assets/util/util";

const BASE_URl = "";

const BASE_COLUMS = [
    {
        field: "venda",
        header: "Venda",
        style: { width: "8%" },
        align: "center",
    },
    {
        field: "devedor.nome",
        header: "Cliente",
        action: ({ devedor }) => `${devedor?.nome} - ${formatDocumento(devedor?.identificacao)}`,
    },
    {
        field: "numero_parcela",
        header: "Parcela",
        align: "center",
        style: { width: "6%" },
        action: ({ numero_parcela, quantidade_parcelas }) => `${numero_parcela}/${quantidade_parcelas}`,
    },
    {
        field: "vencimento",
        header: "Vencimento",
        style: { width: "9%" },
        dateFormat: "dd/MM/yyyy",
        align: "center",
    },
    {
        field: "valor_original",
        header: "Valor original",
        money: "true",
        style: { width: "8%" },
    },
    {
        field: "valor_original",
        header: "Val. atualizado",
        money: "true",
        style: { width: "8%" },
    },
];

const BASE_COLUMN_ACTIONS = {
    field: "actions",
    header: "Ações",
    style: { width: "15%" },
};

export const Listagem = ({ listagemRef, actions }) => {
    const colunas = useMemo(() => {
        if (!actions) return BASE_COLUMS;
        const actionColum = {
            ...BASE_COLUMN_ACTIONS,
            action: (e) => actions(e),
        };
        return [...BASE_COLUMS, actionColum];
    }, [actions]);

    return (
        <MakoListagem
            ref={listagemRef}
            colunas={colunas}
            urlPesquisa={BASE_URl}
            fazerBusca={false}
            configTabela={{
                paginator: true,
                lazy: true,
            }}
        />
    );
};
